import React from 'react';

// Layout imports
import Layout from '../layouts/Default/Default.jsx';
import SEO from '../layouts/SEO/SEO.jsx';

// Component imports
import Introduction from '../components/shared/Introduction/Introduction.jsx';
import PageSelector from '../components/shared/PageSelector/PageSelector.jsx';
import Promotion from '../components/shared/Promotion/Promotion.jsx';
import DesignExamples from '../components/DesignExamples/DesignExamples.jsx';
import ContentListing from '../components/shared/ContentListing/ContentListing.jsx';

// Asset imports
import Me from '../assets/images/content/me.svg';

const Design = () => (
  <Layout>
    <SEO title="UX/UI Web Design" />

    <Introduction
      heading='User Experience &amp;'
      subheading='Interface Design'
      description='Tried and tested industry experience in planning and creating extensive and accessible user journeys for websites and web applications. Creating concise user-focused wireframes and clean, modern designs to help you drive audience engagement.'
      imageSource={Me}
    />

    <PageSelector
      id='mobile-nav-selector'
      triggerText='UX/UI Web Design'
    />

    <Promotion
      heading='Create inclusive and modern designs for your audience'
      description='Planning and creating inclusive and accessible experiences for your users should always be the focus when designing your next website or application. Let’s create flawless user experience.'
      list={[
        {
          icon: '/images/icons/hands.svg',
          text: 'Inclusive design',
        },
        {
          icon: '/images/icons/school.svg',
          text: 'Understand users',
        },
        {
          icon: '/images/icons/networking.svg',
          text: 'Meaningful experiences',
        }
      ]}
    />

    <DesignExamples />

    <ContentListing
      heading='Why good user experience and interface design matters'
      description='Providing a positive experience to your users and keeping them engaged with your brand will keep them loyal to your product or service.'
      list={[
        {
          icon: '/images/icons/mobile.svg',
          title: 'UX in a mobile world',
          text: 'Smartphone usage now accounts for more digital media access than desktops, with a majority of mobile users reporting a bad mobile experience. Understanding how users are accessing your website and online content whilst ensuring a flawless transition between mobile and desktop experience can help drive your success.',
        },
        {
          icon: '/images/icons/search.svg',
          title: 'Identify & solve problems',
          text: 'User experience aims to understand your users and provide positive experiences. Understanding and capturing your user requirements can help to identify and solve any potential problems that may arise in your audience engaging with your brand and online content.',
        },
        {
          icon: '/images/icons/product.svg',
          title: 'Enhance your branding',
          text: 'Good web design impacts how your audience will perceive your brand and services. Clean and modern visual experience can help retain users in today’s highly visual online market. The way your site looks informs a user whether they want to interact with your brand or seek alternative competition.',
        }
      ]}
    />

  </Layout>
)

export default Design;