import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Component imports
import Button from '../Button/Button.jsx';
import Modal from '../Modal/Modal.jsx';
import ContactForm from '../ContactForm/ContactForm.jsx';
import Circle from '../../shared/Circle/Circle.jsx';

// Style imports
import './ContentListing.scss';

const ContentListing = ({
    heading,
    description,
    list,
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleAnimation = () => {
        gsap.registerPlugin(ScrollTrigger);
    
        gsap.to('#content-listing-circle-desktop', {
            scrollTrigger: {
                trigger: '#content-listing-circle-desktop',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });

        gsap.to('#content-listing-circle-mobile', {
            scrollTrigger: {
                trigger: '#content-listing-circle-mobile',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });
    };

    useEffect(() => {
        handleAnimation();
    }, []);

    return (
        <section className='content-listing'>
            <div className='content-listing__container container'>
                <h2 className='content-listing__heading'>{heading}</h2>
                <p className='content-listing__description'>{description}</p>

                <ul className='content-listing__list'>
                    {list.map((item, i) => (
                        <li className='content-listing__list-item' key={i}>
                            <div className='content-listing__image' style={{backgroundImage: `url('${item.icon}')`}}></div>

                            <h3 className='content-listing__subheading'>{item.title}</h3>
                            <p className='content-listing__text'>{item.text}</p>
                        </li>
                    ))}
                </ul>

                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        openModal();
                    }}
                >
                    Contact me
                    <span className='accessible'> Opens modal window</span>
                </Button>
            </div>
            
            {/* Desktop Circles */}
            <Circle
                color='#7E8588'
                height={575}
                width={575}
                top={-280}
                right={-170}
                opacity={0.1}
                desktop
            />

            <Circle
                color='#7E8588'
                height={535}
                width={535}
                top={-280}
                right={-170}
                opacity={0.1}
                thickness={40}
                desktop
            />

            <Circle
                color='#7E8588'
                height={360}
                width={360}
                top={125}
                right={54}
                opacity={0.05}
                thickness={40}
                desktop
            />

            <Circle
                variant='gradient'
                height={576}
                width={576}
                bottom={-215}
                left={-195}
                desktop
                id='content-listing-circle-desktop'
            />

            {/* Mobile Circles */}
            <Circle
                color='#7E8588'
                height={282}
                width={282}
                top={-100}
                right={-190}
                opacity={0.1}
                thickness={15}
                mobile
            />

            <Circle
                color='#7E8588'
                height={252}
                width={252}
                top={-100}
                right={-190}
                opacity={0.1}
                thickness={30}
                mobile
            />

            <Circle
                color='#7E8588'
                height={235}
                width={235}
                top={100}
                right={-190}
                opacity={0.05}
                thickness={20}
                mobile
            />

            <Circle
                variant='gradient'
                height={311}
                width={311}
                bottom={-110}
                left={-165}
                mobile
                id='content-listing-circle-mobile'
            />

            {modalOpen && (
                <Modal
                    modalOpen={modalOpen}
                    onRequestClose={closeModal}
                    modalLabel='Contact modal'
                >
                    <ContactForm />
                </Modal>
            )}
        </section>
    );
};

ContentListing.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
};

export default ContentListing;