import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Component imports
import Button from '../Button/Button.jsx';
import Modal from '../Modal/Modal.jsx';
import ContactForm from '../ContactForm/ContactForm.jsx';
import Circle from '../Circle/Circle.jsx';

// Style imports
import './Promotion.scss';

const Promotion = ({
    heading,
    description,
    list,
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleAnimation = () => {
        gsap.registerPlugin(ScrollTrigger);
    
        gsap.to('#promotion-circle-desktop', {
            scrollTrigger: {
                trigger: '#promotion-circle-desktop',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });

        gsap.to('#promotion-circle-mobile', {
            scrollTrigger: {
                trigger: '#promotion-circle-mobile',
                scrub: 1,
                markers: false,
            },
            rotation: 720,
            ease: 'none',
        });
    };
    
    useEffect(() => {
        handleAnimation();
    }, []);

    return (
        <section className='promotion'>
           <div className='promotion__container container'>
                <h2 className='promotion__heading'>{heading}</h2>
                <p className='promotion__description'>{description}</p>

                <ul className='promotion__list'>
                    {list.map((item, i) => (
                        <li className='promotion__list-item' key={i}>
                            <div className='promotion__image' style={{backgroundImage: `url('${item.icon}')`}}></div>
                            <p className='promotion__text'>{item.text}</p>
                        </li>
                    ))}
                </ul>

                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        openModal();
                    }}
                >
                    Contact me
                    <span className='accessible'> Opens modal window</span>
                </Button>

                {/* Desktop Circles */}
                <Circle
                    variant='gradient'
                    height={535}
                    width={535}
                    bottom={-275}
                    right={-230}
                    desktop
                    id='promotion-circle-desktop'
                />

                <Circle
                    color='#7E8588'
                    height={419}
                    width={419}
                    bottom={15}
                    right={-380}
                    opacity={0.05}
                    desktop
                />

                <Circle
                    color='#7E8588'
                    height={379}
                    width={379}
                    bottom={15}
                    right={-380}
                    opacity={0.05}
                    thickness={40}
                    desktop
                />

                <Circle
                    color='#7E8588'
                    height={360}
                    width={360}
                    top={-160}
                    left={-150}
                    opacity={0.1}
                    thickness={40}
                    desktop
                />

                {/* Mobile Circles */}
                <Circle
                    color='#7E8588'
                    height={297}
                    width={297}
                    top={-120}
                    left={-160}
                    opacity={0.1}
                    thickness={25}
                    mobile
                />

                <Circle
                    variant='gradient'
                    height={283}
                    width={283}
                    bottom={-90}
                    right={-150}
                    mobile
                    id='promotion-circle-mobile'
                />

                <Circle
                    color='#7E8588'
                    height={223}
                    width={223}
                    bottom={63}
                    right={-165}
                    opacity={0.05}
                    thickness={12}
                    mobile
                />

                <Circle
                    color='#7E8588'
                    height={199}
                    width={199}
                    bottom={63}
                    right={-165}
                    opacity={0.05}
                    thickness={24}
                    mobile
                />
           </div>

            {modalOpen && (
                <Modal
                    modalOpen={modalOpen}
                    onRequestClose={closeModal}
                    modalLabel='Contact modal'
                >
                    <ContactForm />
                </Modal>
            )}
        </section>
    );
};

Promotion.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
};

export default Promotion;