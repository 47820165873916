import React from 'react';

// Style imports
import './DesignExamples.scss';

// Asset imports
import JPDevelopment from '../../assets/images/content/jp-development.jpg';
import ParkyGames from '../../assets/images/content/parkygames.jpg';
import MoneyBeans from '../../assets/images/content/moneybeans.png';

const DesignExamples = () => {
    return (
        <section className='design-examples'>
            <div className='design-examples__container container'>

                <div className='design-examples__inner'>
                    <h2 className='design-examples__heading'>Check out some of my designs</h2>
                    <p className='design-examples__description'>View recent examples of personal design projects that I have undertaken – click a link to visit the site or download my case studies report for a more in-depth insight.</p>
                    <a className='button button--hover-outline design-examples__download' href='/documents/Case_Studies.pdf' download='Case_Studies.pdf' role='button'>
                        Download cases
                        <span className='accessible'>
                            <abbr title="Adobe Portable Document Format">PDF</abbr>,
                            688<abbr title="Kilobyte">KB</abbr>
                        </span>
                    </a>
                </div>

                <div className='design-examples__inner'>
                    <ul className='design-examples__list'>
                        <li className='design-examples__list-item'>
                            <a className='design-examples__link' href='http://jpdevelopment.co.uk/'>
                                <div className='design-examples__image-wrapper'>
                                    <img className='design-examples__image' src={JPDevelopment} alt='' aria-hidden='true' />
                                    <span className='design-examples__link-overlay'></span>
                                </div>
                                JP Web Development
                            </a>
                        </li>

                        <li className='design-examples__list-item'>
                            <a className='design-examples__link' href='https://parkygames.com/'>
                                <div className='design-examples__image-wrapper'>
                                    <img className='design-examples__image' src={ParkyGames} alt='' aria-hidden='true' />
                                    <span className='design-examples__link-overlay'></span>
                                </div>
                                ParkyGames YouTube Fansite
                            </a>
                        </li>

                        <li className='design-examples__list-item'>
                            <a className='design-examples__link design-examples__link--dark' href='https://moneybeans.co.uk/'>
                                <div className='design-examples__image-wrapper'>
                                    <img className='design-examples__image' src={MoneyBeans} alt='' aria-hidden='true' />
                                    <span className='design-examples__link-overlay'></span>
                                </div>
                                moneyBeans Finance Tools
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default DesignExamples;